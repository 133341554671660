<template>
    <div class="dialog sub-question" @click.self="dismissDialog">
        <div class="dialog-container">
            <div class="title-bar">
                <span class="title">添加追問</span>
                <span class="close-dialog" @click="dismissDialog"></span>
            </div>
            <div class="sub-question-content">
                <div class="question-title" v-text="title"></div>
                <div class="question-desc">
                    <p class="question-desc-v" :style="{'-webkit-line-clamp': clamp}">問題描述：{{desc}}</p>
                    <a href="javascript:;" class="link-all" v-if="clamp == 1" @click="showAll">#查看全部</a>
                </div>
                <div class="sub-question-hint">追加或總結</div>
                <textarea cols="30" rows="6" placeholder="請輸入您的問題、意見或建議（10~500字以內）" class="sub-question-v" v-model="content"></textarea>
            </div>
            <div class="btn-commit-question" @click="addQuestion"><div class="lds-ring" v-if="loading"><div></div><div></div><div></div><div></div></div>{{loading ? '正在提交...' : '提交追問'}}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: '',
        desc: '',
        askid: '',
        callback: Function,
    },
    data() {
        return {
            clamp: 1,
            loading: false,
            content: '',
        }
    },
    mounted: function(){
        if(!this.desc || this.desc.length * 22 < 936) {
           this.clamp = 1000
        } else {
            this.clamp = 1
        }
    },
    methods: {
        dismissDialog: function() {
            this.callback && this.callback()
        },
        showAll: function() {
            this.clamp = 1000
        },
        async addQuestion(){
            if(this.loading) return
            if(!this.content.trim()) {
                this._toast.warning(this, '請輸入追問內容')
                return
            }
            this.loading = true
            try {
                // QuestionModel q = _questions[index];
                // QuestionModel question = new QuestionModel(askid: q.askid, createdby: q.createdby, note: txt.trim());
                // question.ip = '';
                // question.title = q.title;
                // DataResult rs = await QuestionDao.addSubQuestion(question);
                var rs = await this.$store.dispatch('addQuestion', {
                    ip: '',
                    title: this.title,
                    askid: this.askid,
                    createdby: this.$store.getters.clientId,
                    note: this.content,
                })
                this._toast.success(this, '添加追問成功')
                this.callback && this.callback(true)
            } catch (error) {
                this._toast.warning(this, error || '數據加載失敗...')
            }
            this.loading = false 
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .dialog {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 1000;
        background-color: rgba(4, 58, 61, 0.3);
        overflow: hidden;
    }
    .dialog > .dialog-container {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 936px;
        height: 680px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .dialog> .dialog-container .title-bar {
        display: inline-block;
        height: 46px;
        line-height: 46px;
        background-color: #2B99A6;
        padding-left: 30px;
        font-weight: bold;
        font-size: 20px;
        color: #fff;
    }

    .dialog > .dialog-container .title-bar .close-dialog {
        float: right;
        width: 46px;
        height: 46px;
        position: relative;
        background-color: #36C4D0;
        cursor: pointer;
    }

    .dialog > .dialog-container .title-bar .close-dialog::after {
        width: 20px;
        height: 20px;
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-image: url(../assets/imgs/pc/img_cha_white@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    /* 追加提問 start */
    .dialog.sub-question .sub-question-content {
        max-height: 480px;
        width: 936px;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .dialog.sub-question .sub-question-content > .question-title {
        position: relative;
        margin-top: 60px;
        left: 36px;
        line-height: 18px;
        font-weight: bold;
        color: #231F20;
        font-size: 18px;
        padding-left: 23px;
    }

    .dialog.sub-question .sub-question-content > .question-title::before {
        position: absolute;
        left: 0;
        top: 1px;
        width: 16px;
        height: 16px;
        content: '';
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url(../assets/imgs/pc/img_wen@2x.png);
    }

    .dialog.sub-question .sub-question-content .question-desc {
        margin-left: 60px;
        margin-top: 18px;
        width: 840px;
        display: flex;
        flex-direction: row;
    }

    .dialog.sub-question .sub-question-content .question-desc > .question-desc-v {
        flex: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        color: #969696;
        font-weight: 400;
        font-size: 16px;
        /* height: 16px; */
        line-height: 16px;
    }

    .dialog.sub-question .sub-question-content .question-desc > .link-all {
        font-weight: 400;
        color: #36C4D0;
        font-size: 16px;
        line-height: 16px;
    }

    .dialog.sub-question .sub-question-content > .sub-question-hint {
        margin-top: 53px;
        margin-left: 36px;
        padding-left: 23px;
        position: relative;
        font-weight: bold;
        color: #1B2122;
        font-size: 18px;
        line-height: 18px;
    }

    .dialog.sub-question .sub-question-content > .sub-question-hint::after {
        position: absolute;
        content: '';
        left: 0;
        bottom: 2px;
        width: 15px;
        height: 15px;
        background-image: url(../assets/imgs/pc/img_fankui@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .dialog.sub-question .sub-question-content .sub-question-v {
        width: 824px;
        height: 98px;
        background-color: #E3EFF2;
        padding: 16px 20px;
        margin-left: 36px;
        margin-top: 10px;
    }

    .dialog.sub-question .btn-commit-question {
        position: absolute;
        width: 228px;
        height: 44px;
        background-color: #2B99A6;
        border-radius: 10px;
        color: #fff;
        font-weight: bold;
        font-size: 18px;
        left: 50%;
        cursor: pointer;
        transform: translateX(-50%);
        bottom: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
    }

    .dialog.sub-question .btn-commit-question:active {
        opacity: .7;
    }
    /* 追加提問 end */

    /** loading start */
    .lds-ring {
        display: inline-block;
        position: relative;
        width: 32px;
        height: 32px;
    }
    
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 24px;
        height: 24px;
        margin: 4px;
        border: 4px solid #fff;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
    }
    
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }

    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    /** loading end */ 

</style>