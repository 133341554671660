<template>
	<div class="aq-list">
        <div class="aq-item" v-for="(item, index) in dataList" :key="index">
            <div class="question-item">
                <div class="question-item-head">
                    <img src="../../../assets/imgs/pc/img_yonghu@2x.png" alt="" class="question-avatar">
                    <div class="question-item-head-r">
                        <div class="question-user-info">
                            <span class="gender" v-text="item.gender == 1 ? '男':'女'"></span>
                            <span class="age">{{item.age}}歲</span>
                            <span class="add-time" v-text="item.createdtime"></span>
                        </div>
                        <h2 class="question-item-title">
                            <router-link :to="'/ask/list?IllCate='+item.illnesscategoryid" class="q-category">#{{item.illnesscategorycode}}</router-link>
                            <router-link :to="'/ask/detail/'+item.askid">{{item.title}}</router-link>
                        </h2>
                    </div>
                </div>
                <p class="question-item-content" v-text="'問題描述：' + item.note"></p>
                <div class="sub-q" v-if="item.subAskList.length">
                    <h4 class="sub-q-title"><span class="sub-q-hint">追問:&nbsp;&nbsp;</span>{{item.subAskList[0].note}}</h4>
                </div>
            </div>
            <div :class="{'answer-list-w': true, 'hide': askidList.indexOf(item.askid) == -1}">
                <div class="answer-list">
                    <div class="answer-item" v-for="(answer, i) in item.answerList" :key="i">
                        <img src="../../../assets/imgs/pc/img_nvyis@2x.png" alt="" class="answer-avatar">
                        <div class="answer-item-r">
                            <div class="answer-item-head">
                                醫師
                                <span class="doctor-name" v-text="answer.doctorname"></span>
                                <span class="create-time" v-text="answer.createdtime"></span>
                            </div>
                            <p class="answer-item-content" v-text="answer.note"></p>
                        </div>
                    </div>
                </div>
            </div>
            <router-link class="aq-operate" :to="'/ask/detail/'+item.askid">
                <div class="btn-unshift-q" v-if="!item.subAskList.length" @click="addSubQuestion(item)">追問</div>
                <div class="btn-sea-all" @click="extrendAnswer(item)">{{askidList.indexOf(item.askid) == -1 ? (item.answerList.length + '條回復') : '收起回复'}}</div>
            </router-link>
        </div>
        <div class="aq-foot" v-if="!loading">
            <router-link to="/user/aq-news/question" class="to-aq-page">查看全部&gt;&gt;</router-link>
        </div>
        <loading v-if="loading" />
        <question-dialog v-if="showDialog" :callback="onDialogCallback" :askid="askid" :title="title" :desc="desc"/>
        <split-page v-if="visible" :initData="{pageNo, pageSize, total}" :onPageChanged="onPageChanged"/>
    </div>
</template>

<script>
    import questionDialog from '@/layouts/SubQuestionDialog.vue'
    import Loading from '@/layouts/Loading.vue'
    import SplitPage from '@/layouts/SplitPage.vue'
    import my_ask from '@/views/mixins/my_ask'
	export default {
        mixins: [my_ask],
        components: {
            questionDialog,
            Loading,
            SplitPage
        },
        data(){
            return {
                visible: false,
                showDialog: false,
                style: '',
                clientid: this.$store.getters.clientId,
                pageNo: 1,
                pageSize: 10,
                total: 0,
                dataList: [],
                loading: true,
                askidList: [],
                askid: '',
                title: '',
                desc: '',
            }
        },
        mounted: function(){
            var hash = this.$route.hash
            hash = hash.replace('#', '')
            var list = hash.split('&')
            var key = list[0].split('=')[0]
            var value = list[0].split('=')[1] / 1
            if(key == 'page') {
                if(! isNaN(value)) {
                    this.pageNo = parseInt(value) < 1 ? 1 : parseInt(value)
                }
            }
            this.onRefresh()
        },
        methods: {
            extrendAnswer(askData){
                if(! askData.answerList.length) return
                if(this.askidList.indexOf(askData.askid) == -1) {
                    this.askidList.push(askData.askid)
                } else {
                    this.askidList.splice(this.askidList.indexOf(askData.askid), 1)
                }
            },
            addSubQuestion: function(q){
                //添加追问
                this.askid = q.askid
                this.title = q.title
                this.desc = q.note
                this.showDialog = true
                this.style = document.body.getAttribute('style') || ''
                document.body.setAttribute('style', this.style + 'overflow: hidden;')
            },
            onDialogCallback: function(success){
                this.showDialog = false
                document.body.setAttribute('style', this.style)
                if(success) {
                    setTimeout(() => {
                        window.location.reload()
                    }, 1500)
                }
            },
            onRefresh: function(){
                this.loading = true
                this.loadData({
                    clientid: this.$store.getters.clientId,
                    pageNo: this.pageNo,
                    pageSize: this.pageSize
                }, (data, error)=>{
                    this.loading = false
                    if(data) {
                        this.dataList = data.list
                        this.total = data.total
                    } else {
                        if(error) {
                            this._toast.warning(this, error)
                        }
                    }
                })
            },
            onPageChanged: function(pageNo){
                this.$router.push({path: '/my-ask#page='+pageNo},(e)=>{});
                if(this.pageNo != pageNo) {
                    this.pageNo = pageNo
                    this.onRefresh();
                }
            },
        },
        watch: {
			$route(to,from){
				if(!to.hash && this.pageNo != 1) {
                    this.pageNo = 1
                    this.visible = false
                    this.onRefresh()
                }
			}
		}
    }
</script>

<style lang="scss" scoped>
    /* 提问的问题项 start */
    .aq-list {
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .aq-list .aq-item .question-item {
        margin-top: 24px;
        position: relative;
    }

    .aq-list .aq-item .question-item .question-item-head {
        display: flex;
        flex-direction: row;
    }

    .aq-list .aq-item .question-item .question-item-head-r {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .aq-list .aq-item .question-item .question-avatar {
        display: inline-block;
        width: 32px;
        height: 32px;
        margin-right: 8px;
    }

    .aq-list .aq-item .question-item .question-user-info {
        flex: 1;
        display: flex;
        flex-direction: row;
        height: 13px;
        line-height: 13px;
        margin-bottom: 8px;
        width: 630px;
        font-size: 14px;
        font-weight: 400;
        color: #969696;
    }

    .aq-list .aq-item .question-item .question-user-info > .age {
        margin-left: 8px;
    }

    .aq-list .aq-item .question-item .question-user-info > .add-time {
        margin-left: 20px;
    }

    .aq-list .aq-item .question-item .question-item-title {
        flex: 1;
        font-size: 18px;
        font-weight: bold;
        color: #231F20;
        height: 18px;
        line-height: 24px;
        text-align: left;
        text-overflow: ellipsis;
    }

    .aq-list .aq-item .question-item .question-item-title > .q-category {
        color: #FF7800;
        margin-right: 8px;
    }

    .aq-list .aq-item .question-item .question-item-content {
        line-height: 22px;
        font-weight: 400;
        color: #969696;
        margin: 13px 0px 0px 41px;
        width: 598px;
        font-size: 16px;
        color: #969696;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    /* 提问的问题项 end */

    /* 医师回答的答案 start */
    .aq-list .aq-item {
        padding-bottom: 20px;
        margin: 10px 12px 0px 12px;
        border-bottom: 1px solid #E8E8E8;
    }

    .aq-list .aq-item:nth-child(10) {
        border-bottom: none;
    }

    .aq-list .answer-list-w {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        max-height: unset;
        transition: all .5s cubic-bezier(0, 1, 0.5, 1);
    }

    .aq-list .answer-list-w {
        margin-top: 28px;
    }

    .aq-list .answer-list-w.hide {
        margin-top: 0px;
        max-height: 0px;
    }

    .aq-list .answer-list-w .answer-list {
        transform: translateY(0);
        transition: all .5s cubic-bezier(0, 1, 0.5, 1);
    }

    .aq-list .answer-list-w.hide .answer-list {
        transform: translateY(-100%);
    }

    .aq-list .answer-item {
        display: flex;
        flex-direction: row;
        margin-top: 24px;
    }

    .aq-list .answer-item:nth-child(1) {
        margin-top: 0px;
    }

    .aq-list .answer-item .answer-avatar {
        display: inline-block;
        width: 32px;
        height: 32px;
        margin-right: 8px;
    }

    .aq-list .answer-item .answer-item-r {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .aq-list .answer-item .answer-item-r .answer-item-head {
        flex: 1;
        font-weight: 400;
        color: #969696;
        font-size: 14px;
        line-height: 14px;
    }

    .aq-list .answer-item .answer-item-r .answer-item-content {
        flex: 1;
        font-weight: 400;
        font-size: 16px;
        color: #231F20;
        line-height: 24px;
        margin-top: 7px;
    }

    .aq-list .aq-operate {
        margin: 16px 0px 0px 42px;
        display: flex;
        flex-direction: row;
        position: relative;
    }

    .aq-list .aq-operate .btn-unshift-q,
    .aq-list .aq-operate .btn-sea-all {
        height: 27px;
        line-height: 27px;
        position: relative;
        padding-left: 39px;
        padding-right: 13px;
        font-weight: 400;
        color: #008C98;
        font-size: 14px;
        background-color: #DCF7FB;
        border-radius: 8px;
        display: inline-block;
        cursor: pointer;
        margin-right: 45px;
    }

    .aq-list .aq-operate .btn-unshift-q,
    .aq-list .aq-operate .btn-sea-all {
        margin-right: 20px;
    }

    .aq-list .aq-operate .btn-unshift-q {
        background-color: #FFE3D5;
        color: #72240B;
    }

    .aq-list .aq-operate .btn-unshift-q::after,
    .aq-list .aq-operate .btn-sea-all::after {
        content: '';
        position: absolute;
        left: 11px;
        top: 6px;
        width: 17px;
        height: 15px;
        background-image: url(../../../assets/imgs/pc/img_huifu@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .aq-list .aq-operate .btn-unshift-q::after {
        background-image: url(../../../assets/imgs/pc/img_zhuiwen@2x.png);
    }

    .aq-list .aq-operate .btn-sea-all.extrend {
        padding-left: 0;
        color: transparent;
        margin-right: 0px;
        width: 104px;
        padding: 0;
    }

    .aq-list .aq-operate .btn-sea-all.extrend::after {
        content: '收起回復';
        position: absolute;
        left: 0px;
        top: 0px;
        width: 90px;
        height: auto;
        font-size: 14px;
        line-height: 27px;
        color: #36C4D0;
        font-weight: bold;
        background-image: none;
        text-align: right;
    }

    .aq-list .aq-operate .btn-sea-all.extrend::before {
        content: '';
        position: absolute;
        left: 11px;
        top: 6px;
        width: 17px;
        height: 15px;
        background-image: url(../../../assets/imgs/pc/img_huifu@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .aq-list .aq-operate .btn-save-aq {
        position: absolute;
        left: 176px;
        top: 0px;
        height: 27px;
        line-height: 27px;
        padding-left: 37px;
        padding-right: 13px;
        font-weight: 400;
        color: #969696;
        font-size: 14px;
        cursor: pointer;

        display: none;
    }

    .aq-list .aq-operate .btn-save-aq > span {
        position: absolute;
        font-weight: 600;
        color: #FF8000;
        font-size: 14px;
        opacity: 0;
        right: 0px;
        transform: translateY(100%);
        transition: all .5s ease-in-out;
    }

    .aq-list .aq-operate .btn-save-aq.saved {
        position: absolute;
        left: 176px;
        top: 0px;
        height: 27px;
        line-height: 27px;
        padding-left: 39px;
        padding-right: 13px;
        font-weight: 400;
        color: #FF8000;
        font-size: 14px;
        cursor: pointer;
    }

    .aq-list .aq-operate .btn-save-aq::after {
        content: '';
        position: absolute;
        left: 10px;
        top: 6px;
        width: 17px;
        height: 14px;
        background-image: url(../../../assets/imgs/pc/img_shoucang@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .aq-list .aq-operate .btn-save-aq.saved::after {
        background-image: url(../../../assets/imgs/pc/img_shoucangdao@2x.png);
    }

    .aq-list .aq-operate .btn-un-save-aq {
        float: right;
        height: 27px;
        line-height: 27px;
        padding-left: 5px;
        padding-right: 5px;
        margin-right: 12px;
        font-weight: 400;
        color: #969696;
        font-size: 14px;
        cursor: pointer;
        text-decoration: underline;
    }

    /* 医师回答的答案 end */

    .sub-q {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-top: 17px;
        margin-left: 41px;
    }

    .sub-q .sub-q-hint,
    .sub-q > .sub-q-title {
        font-size: 16px;
        line-height: 24px;
        color: #1B2122;
        font-weight: 400;
    }

    .sub-q > .sub-q-title {
        font-weight: bold;
    }

    .sub-q .sub-q-hint {
        position: relative;
        padding-left: 24px;
    }

    .sub-q .sub-q-hint::after {
        position: absolute;
        content: '';
        left: 0px;
        bottom: 3px;
        width: 15px;
        height: 15px;
        background-image: url(../../../assets/imgs/pc/img_fankui@2x.png);   
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }


    .aq-foot {
        height: 48px;
        line-height: 48px;
        border-top: 1px solid #E8E8E8;
        text-align: center;
        background-color: #fff;
    }

    .aq-foot > a {
        display: inline-block;
        text-decoration: underline;
        font-weight: 400;
        color: #36C4D0;
        font-size: 16px;
    }
</style>
